import * as React from "react";
import Layout from "../components/layout";
import solteraImg from "../images/soltera-specs.jpg";
import solteraVideo from "../images/Soltera_Video.mp4";
import { pageStyles } from "../styles/helpers";
import "../styles/global-styles.css";

// styles
const imageStyles = {
  marginTop: "0",
  marginBottom: 0,
  width: "100%",
};
const videoStyles = {
  marginTop: "-5px",
  marginBottom: 0,
  width: "100%",
};

// markup
const Soltera = () => {
  return (
    <main style={pageStyles}>
      <title>soltera Specs</title>
      <img style={imageStyles} src={solteraImg} />
      <video
        style={videoStyles}
        crossOrigin="anonymous"
        controls={false}
        autoPlay
        loop
        muted
      >
        <source src={solteraVideo} type="video/mp4" />
      </video>
    </main>
  );
};

export default Soltera;
